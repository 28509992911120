<template>
  <el-dialog
    class="obsercation_dialog"
    title="监测点详情"
    :inline="true"
    @close="handleClose"
    :visible="show"
    width="65%"
  >
    <el-form label-width="140px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="监测点："
            ><span>{{ showData.Name }}</span></el-form-item
          >
          <el-form-item label="联系人："
            ><span>{{ showData.Contact }}</span></el-form-item
          >
          <el-form-item label="详细地址："
            ><span>{{ showData.Addr }}</span></el-form-item
          >
          <el-form-item label="类型："
            ><span>{{ showData.Status | filter }}</span></el-form-item
          >
          <el-form-item label="所属单位："
            ><span>{{ showData.Owner }}</span></el-form-item
          >
          <el-form-item label="菜系："
            ><span>{{ transformCuisine(showData.Cuisine) }}</span></el-form-item
          >
          <el-form-item label="是否联动："
            ><span>{{
              showData.LinkStatus === 1 ? "是" : "否"
            }}</span></el-form-item
          >
          <el-form-item label="风速："
            ><span>{{ showData.FanSpeed }}</span></el-form-item
          >
          <el-form-item label="风量："
            ><span>{{ showData.FanQuantity }}</span></el-form-item
          >
          <el-form-item label="管道截面面积："
            ><span>{{ showData.PipeArea }}</span></el-form-item
          >
          <el-form-item label="灶头数量："
            ><span>{{ showData.StoveNum }}</span></el-form-item
          >
          <el-form-item label="集气灶面积："
            ><span>{{ showData.StoveArea }}</span></el-form-item
          >
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属地区："
            ><span>{{ showData.AreaId }}</span></el-form-item
          >
          <el-form-item label="联系电话："
            ><span>{{ showData.Mobile }}</span></el-form-item
          >
          <el-form-item label="经度："
            ><span>{{ showData.Lng }}</span></el-form-item
          >
          <el-form-item label="纬度："
            ><span>{{ showData.Lat }}</span></el-form-item
          >
          <el-form-item label="日均排烟时间："
            ><span>{{ showData.ExhaustTime }}</span></el-form-item
          >
          <el-form-item label="净化器信息："
            ><span>{{ showData.FilterInfo }}</span></el-form-item
          >
          <el-form-item label="备注："
            ><span>{{ showData.Remark }}</span></el-form-item
          >
          <el-form-item label="风机状态："
            ><span>{{
              showData.FanStatus === 1 ? "正常" : "不正常"
            }}</span></el-form-item
          >
          <el-form-item label="净化器状态："
            ><span>{{
              showData.FilterStatus === 1 ? "正常" : "不正常"
            }}</span></el-form-item
          >
          <el-form-item label="油烟阈值："
            ><span>{{ showData.EmissionsSill }}</span></el-form-item
          >
          <el-form-item label="异常判定："
            ><span>{{ showData.OfflineJudge }}</span></el-form-item
          >
          <el-form-item label="抽样次数："
            ><span>{{ showData.Samplings }}</span></el-form-item
          >
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  props: {
    // 是否显示一点一挡
    show: {
      type: Boolean,
      default: false
    },
    showData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      cuisine: []
    }
  },
  filters: {
    filter(type) {
      const op = {
        1: '一般监测点',
        2: '特殊监测点',
        99: '废弃监测点'
      }
      return op[type]
    }
  },
  created() {
    this.$get('admin/listAllCuisine').then((res) => {
      this.cuisine = res
    })
  },
  methods: {
    /**
     * @description 关闭对话框
     */
    handleClose() {
      this.$emit('on-close', false)
    },
    transformCuisine(k) {
      for (let i = 0, len = this.cuisine.length; i < len; i++) {
        if (this.cuisine[i].Id === k) {
          return this.cuisine[i].Name
        }
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.obsercation_dialog {
  .el-form-item {
    margin-bottom: 0;

    .el-form-item__content {
      span {
        line-height: 40px;
      }
    }
  }
}
</style>
