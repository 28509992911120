<!--在线监管-报警管理-监测详情-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model.trim="filter.mn"
            placeholder="设备编码"
            @keyup.native.enter.stop="getData"
            clearable
          >
            <el-button
              slot="prepend"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="系统状态"
            v-model="filter.SystemStatus"
            @change="getData"
            clearable
          >
            <el-option label="正常" :value="1"></el-option>
            <el-option label="净化器异常" :value="2"></el-option>
            <el-option label="净化器监控异常" :value="3"></el-option>
            <el-option label="风机监控异常" :value="4"></el-option>
            <el-option label="油烟监测异常" :value="5"></el-option>
            <el-option label="监控异常" :value="6"></el-option>
            <el-option label="净化器未供电" :value="7"></el-option>
            <el-option label="监测仪、风机监控异常" :value="8"></el-option>
            <el-option label="异常离线" :value="9"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="filter.Owner"
            @change="getData"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filter.period"
            type="daterange"
            @change="getData"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="openExportSizeInput"
            :loading="exportLoading"
          >导出
          </el-button
          >
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="showMN">显示设备编号</el-checkbox>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- table列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        @sort-change="handleSort"
        size="mini"
        show-summary
        :summary-method="getSummaries"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" align="center" width="55"/>
        <el-table-column
          prop="LocaleName"
          label="监测点"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="LocaleAddr"
          label="地址"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          v-if="showMN"
          key="FanMN"
          prop="FanMN"
          label="风机设备编号"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FanStatus"
          label="风机状态"
          show-overflow-tooltip
          width="90"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ FanFormatter('status', row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="fan_current"
          label="风机电流值"
          align="center"
          width="95"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ FanFormatter('value', row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showMN"
          key="FilterMN"
          prop="FilterMN"
          label="净化器设备编号"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FilterStatus"
          label="净化器状态"
          width="90"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ FilterFormatter('status', row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="pur_current"
          label="净化器电流值"
          width="95"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ FilterFormatter('value', row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="FilterFanLinkRatio"
          label="联动比"
          width="85"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            {{ row.FilterFanLinkRatio === -1 ? '-' : row.FilterFanLinkRatio * 100 + '%' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="FilterAbnormallyUsed"
          label="是否正常使用净化器"
          align="center"
          width="85"
          header-align="center"
        >
          <template slot-scope="{ row }">
            {{ row.FilterAbnormallyUsed === null ? '-' : row.FilterAbnormallyUsed ? '否' : '是' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="showMN"
          key="MN"
          prop="MN"
          label="监测仪设备编号"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Status"
          label="监测仪状态"
          show-overflow-tooltip
          width="95"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ StatusFormatter(row.Status) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SystemStatus"
          label="监测系统状态"
          width="110"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ SystemStatusFormatter(row.SystemStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="func"
          label="操作"
          align="center"
          header-align="center"
          width="100"
          fixed="right"
        >
          <template slot-scope="scope">
            <i
              title="历史C排"
              @click="
                handleVline(
                  scope.row.LocaleId,
                  scope.row.Owner,
                  scope.row.LocaleEmissionsSill,
                  scope.row.LocaleGranuleSill,
                  scope.row.LocaleHydrocarbonSill
                )
              "
              class="opt el-icon-s-data"
            />
            <i
              title="监测点详情"
              class="opt el-icon-orange"
              @click="handleObservation(scope.row)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="(v) => handlePageChange(v, 'PerPage')"
        @current-change="(v) => handlePageChange(v, 'Page')"
        :current-page.sync="filter.Page"
        :page-sizes="[20, 50, 100]"
        :page-size="filter.PerPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.resultsPageInfo.Total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>
    <!-- 监测点详情 弹窗-->
    <observationDialog
      :show="isShowObservationDialog"
      :showData="isShowObservationDialogData"
      @on-close="isShowObservationDialog = false"
    ></observationDialog>
    <!-- 历史减排 -->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      :destory-on-close="true"
      @close="handleHistogramClose"
    >
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-date-picker
            v-model="filter.period"
            type="daterange"
            value-format="timestamp"
            @change="handleVline"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="handleExport"
          >导出
          </el-button
          >
        </el-form-item>
        <ve-histogram
          :data="dataOption"
          :mark-line="markLine"
          :extend="extend"
          :colors="['#00A1FF', '#F5B049', '#009999']"
          :settings="chartSettings"
          :grid="grid"
          :data-empty="dataOption.rows.length === 0"
        />
      </el-form>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import _1 from '@/asset/img/1.png'
import _2 from '@/asset/img/2.png'
import _3 from '@/asset/img/3.png'
import _4 from '@/asset/img/4.png'
import {
  join,
  dateFormater,
  stg,
  export_json,
  getUserInfo,
  getSinglePname,
  underline,
  getPname,
  dataFormatter,
  base64ToBlob
} from '@/util/index'
import observationDialog from '@/component/ObservationDialog'
import VeHistogram from 'v-charts/lib/histogram.common'
import dayjs from "dayjs";
import log from "echarts/src/scale/Log";

export default {
  name: 'AlarmDetectorDetail',
  components: {observationDialog, VeHistogram},
  data() {
    this.chartSettings = {
      metrics: ['油烟折算浓度', '颗粒物折算浓度', '非甲烷总烃折算浓度'],
      dimension: ['采集时间'],
      yAxisName: ['单位:mg/m³']
    }
    this.extend = {
      'xAxis.0.axisLabel.rotate': 45
    }
    this.grid = {
      show: true,
      top: 50,
      left: 10
    }
    return {
      markLine: null,
      isShowObservationDialog: false, // 是否显示监测点详情弹窗
      isShowObservationDialogData: {}, // 监测点详情弹窗
      url:
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      exportLoading: false,
      loading: false,
      datalist: {
        content: [],
        resultsPageInfo: {
          Total: 0
        }
      },
      dataRecord: {},
      mn: null,
      owner: null,
      role: null,
      deviceInfo: {},
      userInfo: {},
      pdfRecordVisible: false,
      pdfList: [],
      dlg: {
        visible: false,
        type: null,
        title: ''
      },
      filter: {
        Status: null,
        AcquitDate: null,
        SortBy: '',
        SortMode: '',
        alarmPage: 1,
        Page: 1,
        alarmSize: 20,
        PerPage: 20,
        IsDetail: true,
        SystemStatus: null,
        period: null
      },
      dataOption: {
        columns: [
          '采集时间',
          '油烟折算浓度',
          '颗粒物折算浓度',
          '非甲烷总烃折算浓度'
        ],
        rows: []
      },
      isShowNotificationDialog: false,
      isShowNotificationDialogData: {
        markLine: {
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'solid'
              },
              label: {
                position: 'start',
                formatter: '{c} '
              }
            }
          },
          data: []
        },
        dataOption: {
          columns: ['采集时间', '油烟折算浓度'],
          rows: []
        }
      },
      tenMinOption: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['油烟浓度', '颗粒物浓度', '非甲烷总烃浓度']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 143
          }
        },
        yAxis: {
          type: 'value',
          name: '平均浓度mg/m³'
        },
        series: [
          {
            name: '油烟浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#00A1FF'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          },
          {
            name: '颗粒物浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#F5B049'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          },
          {
            name: '非甲烷总烃浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#009999'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          }
        ]
      },
      showMN: false
    }
  },
  computed: {
    ...mapState(['clientHeight', 'customerTree', 'user']),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    })
  },
  created() {
    this.userInfo = getUserInfo()
    this.role = stg().getItem('role')
    this.getData()
  },
  methods: {
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.resultsPageInfo.Total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    getSummaries(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        switch (column.property) {
          case 'LocaleName':
          case 'LocaleAddr':
          case 'FanStatus':
          case 'FilterStatus':
          case 'FilterFanLinkRatio':
          case 'FilterAbnormallyUsed':
          case 'Status':
          case 'SystemStatus':
          case 'func':
            sums[index] = ''
            return
        }
        const values = data.map(item => Number(item[column.property]))
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr)
          if (!isNaN(value)) {
            return prev + curr
          } else {
            return prev
          }
        }, 0).toFixed(2)
      })
      return sums
    },
    handleHistogramClose() {
      this.dataOption = {
        columns: [
          '采集时间',
          '油烟折算浓度',
          '颗粒物折算浓度',
          '非甲烷总烃折算浓度'
        ],
        rows: []
      }
      this.filter.period = null
    },
    handleExport() {
      export_json(
        dateFormater(this.filter.period[0] / 1000, false) +
        '至' +
        dateFormater(this.filter.period[1] / 1000, false) +
        '-' +
        this.mn,
        this.dataOption.rows,
        {
          油烟折算浓度: '油烟折算浓度',
          颗粒物折算浓度: '颗粒物折算浓度',
          非甲烷总烃折算浓度: '非甲烷总烃折算浓度',
          采集时间: '采集时间'
        }
      )
    },
    /**
     * @description 系统状态格式化
     * @param systemStatus 系统状态 [1-9]
     * @return string
     */
    SystemStatusFormatter(systemStatus) {
      switch (systemStatus) {
        case 1:
          return '正常'
        case 2:
          return '净化器异常'
        case 3:
          return '净化器监控异常'
        case 4:
          return '风机监控异常'
        case 5:
          return '油烟监测异常'
        case 6:
          return '监控异常'
        case 7:
          return '净化器未供电'
        case 8:
          return '监测仪、风机监控异常'
        case 9:
          return '异常离线'
        default:
          return ''
      }
    },
    /**
     * @param status 监测仪状态 [1-4]
     * @return string
     */
    StatusFormatter(status) {
      switch (status) {
        case 1:
        case 2:
          return '开'
        case 3:
        case 4:
          return '离线'
        default:
          return ''
      }
    },
    /**
     * @description 对风机状态和电流值进行格式化
     * @param row row.FanStatus & row.fan_current
     * @param type 格式化类型 ['status', 'value']
     * @return string
     */
    FanFormatter(type, row) {
      switch (type) {
        case 'status':
          switch (row.FanStatus) {
            case 1:
              return '开'
            case 2:
              return '关'
            default:
              return ''
          }
        case 'value':
          if (row.FanStatus !== 1) {
            return '-'
          }
          return row.fan_current
        default:
          return ''
      }
    },
    /**
     * @description 对净化器状态和电流值进行格式化
     * @param row row.FilterStatus & row.filter_current
     * @param type 格式化类型 ['status', 'value']
     * @return string
     */
    FilterFormatter(type, row) {
      switch (type) {
        case 'status':
          switch (row.FilterStatus) {
            case 1:
              return '开'
            case 2:
              return '关'
            case 3:
              return '异常'
            default:
              return ''
          }
        case 'value':
          if (row.FilterStatus !== 1 && row.FilterStatus !== 3) {
            return '-'
          }
          return row.pur_current
        default:
          return ''
      }
    },
    handleObservation(obj) {
      const par = {Param: {id: obj.LocaleId}}
      // const par = {StartAt: (this.filter.alarmPage - 1) * this.filter.alarmSize, Size: this.filter.alarmSize, Typ: this.typ, Param: { mn: this.mn }}
      this.$post('admin/listLocale', par)
        .then((res) => {
          this.isShowObservationDialog = true
          this.isShowObservationDialogData = res.content[0]
          if (res.content[0].Owner) {
            this.isShowObservationDialogData.Owner = getPname(
              this.customerTree,
              res.content[0].Owner,
              'Org'
            ).join('/')
          }
          if (res.content[0].AreaId) {
            this.isShowObservationDialogData.AreaId = getSinglePname(
              this.areaTree,
              res.content[0].AreaId
            )
          }
        })
        .catch(() => {
        })
    },

    handleVline(LocaleId, owner, sill, GranuleSill, HydrocarbonSill) {
      if (typeof LocaleId === 'string') {
        this.LocaleId = LocaleId
        this.markLine = {
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'solid'
                // colors: ['#00A1FF', '#F5B049', '#009999'],
              },
              label: {
                // show: false,
                position: 'start',
                formatter: '{c} '
              }
            }
          },
          data: [
            {
              lineStyle: {
                color: '#00A1FF'
              },
              yAxis: sill
            },
            {
              lineStyle: {
                color: '#F5B049'
              },
              yAxis: GranuleSill
            },
            {
              lineStyle: {
                color: '#009999'
              },
              yAxis: HydrocarbonSill
            }
          ]
        }
      }
      if (owner) {
        this.owner = getPname(this.customerTree, owner, 'Org').join('/')
      }
      const para = {LocaleId: this.LocaleId}
      if (!this.filter.period) {
        this.filter.period = []
        const now = new Date()
        now.setDate(now.getDate() - 1)
        now.setHours(0, 0, 0, 0)
        this.filter.period[1] = now.getTime()
        now.setDate(now.getDate() - 30)
        this.filter.period[0] = now.getTime()
      }
      para['AcquitAtBegin'] = this.filter.period[0] / 1000
      para['AcquitAtEnd'] = this.filter.period[1] / 1000
      this.$post('admin/queryDataDetectorDaily', para).then((res) => {
        this.dataOption.rows = []
        let data
        for (
          let i = para['AcquitAtBegin'];
          i <= para['AcquitAtEnd'];
          i += 86400
        ) {
          data = res.find((e) => e.AcquitAt === i)
          this.dataOption.rows.push({
            采集时间: dateFormater(i, false),
            油烟折算浓度: data ? data.CEmissions : 0,
            颗粒物折算浓度: data ? data.CGranule : 0,
            非甲烷总烃折算浓度: data ? data.CHydrocarbon : 0
          })
        }
      })
      this.dlg.visible = true
    },
    getData() {
      this.loading = true
      const para = Object.assign({}, this.filter)
      if (!para.AcquitDate) {
        const time = dayjs().date(dayjs().date() - 1).format('YYYY-MM-DD')
        this.filter.AcquitDate = time
        para.AcquitDate = time
      }
      if (para.period) {
        para.period[0] = dayjs(para.period[0]).format('YYYY-MM-DD')
        para.period[1] = dayjs(para.period[1]).format('YYYY-MM-DD')
      }
      if (para.Owner && para.Owner.length) {
        para.Owner = join(para.Owner)
      } else if (para.Owner && para.Owner.length === 0) {
        para.Owner = undefined
      }
      para.LocaleWithDevice = !this.filter.LocaleWithDevice
      for (const key in para) {
        if (para[key] === '') delete para[key]
      }
      this.$post('admin/queryDataDetectorDaily2', para).then((res) => {
        this.datalist = {...res}
        this.loading = false
      })
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      const para = Object.assign({}, this.filter)
      para.IsDownload = true
      para.PerPage = exportSize
      if (!para.AcquitDate) {
        const now = new Date()
        now.setTime(now.getTime() - 86400000)
        const yesY = now.getFullYear()
        const yesM =
          now.getMonth() < 9 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1
        const yesD = now.getDate() < 10 ? '0' + now.getDate() : now.getDate()
        this.filter.AcquitDate = `${yesY}-${yesM}-${yesD}`
        para.AcquitDate = `${yesY}-${yesM}-${yesD}`
      }
      if (para.Owner && para.Owner.length) {
        para.Owner = join(para.Owner)
      } else if (para.Owner && para.Owner.length === 0) {
        para.Owner = undefined
      }
      para.LocaleWithDevice = !this.filter.LocaleWithDevice
      for (const key in para) {
        if (para[key] === '') delete para[key]
      }
      this.$post('admin/queryDataDetectorDaily2', para).then((res) => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      })
    },
    hourFormatter(r, c) {
      return (r[c.property] / 60).toFixed(2)
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property])
    },
    doneFormatter(r, c, v) {
      return r.DownNum - v
    },
    userFormatter(r, c) {
      return dataFormatter(r[c.property], this.user)
    },
    customerFormatter(r, c) {
      // return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    faultFormatter(r, c, v) {
      return v
    },
    downFormatter(r, c, v) {
      return v
    },
    getImg(status) {
      switch (status) {
        case 1:
          return _1
        case 2:
          return _2
        case 3:
          return _3
        case 4:
          return _4
      }
    },
    getAlarmRecord() {
      var para = {
        StartAt: (this.filter.alarmPage - 1) * this.filter.alarmSize,
        Size: this.filter.alarmSize,
        Typ: this.typ,
        Param: {LocaleId: this.LocaleId}
      }
      this.$post('admin/listAlarmRecord', para)
        .then((res) => {
          this.dataRecord = res
        })
        .catch(() => {
        })
    },
    // 一点一档--历史记录
    handlePdfRecordList(row) {
      // this.localeId = row.LocaleId;
      this.pdfRecordVisible = true
      this.pdfList = []
      this.$post('admin/queryLocaleArchive', {LocaleId: row.LocaleId})
        .then((res) => {
          this.pdfList = res
        })
        .catch(() => {
        })
    },
    // 查看历史一点一档
    handleLook(row) {
      this.download(row.LocaleId, row.Archive)
    },
    // 下载图片
    download(name, imgData) {
      this.downloadFile(name, imgData)
    },
    // 下载
    downloadFile(fileName, content) {
      const aLink = document.createElement('a')
      const blob = base64ToBlob(content) // new Blob([content]);
      const evt = document.createEvent('HTMLEvents')
      evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName
      aLink.href = URL.createObjectURL(blob)
      // aLink.dispatchEvent(evt);
      // aLink.click()
      aLink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      ) // 兼容火狐
    },
    handleOption() {
      this.filter.page = 1
      this.filter.by = 'Status'
      this.filter.mode = 'asc'
      this.getData()
    },
    handleSort(c) {
      if (c.order) {
        this.filter.by = underline(c.prop)
        this.filter.mode = c.order.replace(/ending/g, '')
      }
      this.getData()
    },
    handleAlarmRecord(id, typ, owner) {
      this.LocaleId = id
      this.typ = typ
      this.filter.alarmPage = 1
      this.owner = getPname(this.customerTree, owner, 'Org').join('/')
      this.getAlarmRecord()
      this.dlg.visible = true
    },
    handleEdit(i, row) {
      if (row.Solution === '' || row.editable) {
        if (row.editable) {
          this.changeState = true
          this.$post('admin/alarmProcess', {
            id: row.Id,
            solution: row.Solution
          })
        }
        this.$set(
          this.dataRecord.content,
          i,
          Object.assign(row, {
            editable: !row.editable,
            ProcessAt: Date.parse(new Date()) / 1000,
            Processor: this.userInfo.Name
          })
        )
      }
    },
    getDeviceInfo(id) {
      this.$get('admin/getDevice', {id})
        .then((res) => {
          this.deviceInfo = res
        })
        .catch(() => {
        })
    },
    handleDelRecord(mn) {
      this.$confirm('确定清空所有报警吗?', '提示', {
        type: 'warning'
      })
        .then(() => {
          this.$get('admin/delAlarmRecord', {mn})
            .then(() => {
              this.handleRefresh()
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    handleRefresh() {
      this.filter.page=1
      this.filter.status = null
      this.filter.mn = null
      this.filter.owner = null
      this.getData()
    },
    handlePageChange(val, field) {
      this.filter[field] = val
      if (field.indexOf("alarm") > 0) {
        this.getAlarmRecord()
      } else {
        this.getData()
      }
    },
    handlerClose: function () {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh()
      }
      this.device = []
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    },
    handlePdfRecord(data) {
      // console.log(data)
      let now = new Date()
      now = now.getTime()
      const last = now - 30 * 24 * 3600 * 1000
      const par = {
        LocaleId: data.LocaleId,
        AcquitAtBegin: parseInt(last / 1000),
        AcquitAtEnd: parseInt(now / 1000)
      }
      const list = []
      let customForm = {}
      this.$post('admin/listDataDetectorDailySumDaysByLocaleId', par)
        .then((res) => {
          list.push(res)
          customForm = {...res.customer}
          this.$post('admin/listTenMinDataLastDays', {
            mn: data.MN,
            // mn: '010000A8900016F019060199',
            LastDays: 7
          }).then((response) => {
            // console.log(response)
            this.tenMinOption.series[0]['data'] = []
            this.tenMinOption.series[0]['markLine']['data'][0]['yAxis'] =
              data.EmissionsSill || 0
            this.tenMinOption.xAxis['data'] = []
            this.tenMinOption.series[1]['data'] = []
            this.tenMinOption.series[1]['markLine']['data'][0]['yAxis'] =
              data.GranuleSill || 0
            this.tenMinOption.series[2]['data'] = []
            this.tenMinOption.series[2]['markLine']['data'][0]['yAxis'] =
              data.HydrocarbonSill || 0
            if (response && response.length !== 0) {
              const endTime = response[0]['acquit_at'] + 143 * 7
              for (var i = response[0]['acquit_at']; i <= endTime; i++) {
                let obj = {
                  emissions_conc: 0,
                  granule_conc: 0,
                  hydrocarbon_conc: 0,
                  counter: 1
                }
                response.forEach((e) => {
                  if (e.acquit_at === i) {
                    obj = e
                  }
                })
                this.tenMinOption.series[0]['data'].push(
                  (obj.emissions_conc / obj.counter).toFixed(2)
                )
                this.tenMinOption.series[1]['data'].push(
                  (obj.granule_conc / obj.counter).toFixed(2)
                )
                this.tenMinOption.series[2]['data'].push(
                  (obj.hydrocarbon_conc / obj.counter).toFixed(2)
                )
                this.tenMinOption.xAxis['data'].push(
                  dateFormater(i * 600, true, false)
                )
              }
              this.$forceUpdate()
            }
          })
        })
        .then((response) => {
          // const tpl = { mn: '010000A8900016F018010029' };
          const tpl = {localeId: data.LocaleId}
          tpl['AcquitAtBegin'] = parseInt(last / 1000)
          tpl['AcquitAtEnd'] = parseInt(now / 1000)
          this.$post('admin/queryDataDetectorDaily', tpl).then((res) => {
            this.isShowNotificationDialogData = Object.assign(
              {},
              this.isShowNotificationDialogData,
              customForm
            )
            this.isShowNotificationDialogData.LocaleId = tpl.localeId
            this.isShowNotificationDialogData.beginDate = dateFormater(
              tpl['AcquitAtBegin'],
              false
            )
            this.isShowNotificationDialogData.endDate = dateFormater(
              tpl['AcquitAtEnd'],
              false
            )
            let data
            this.isShowNotificationDialogData.dataOption.rows = []
            for (
              var i = tpl['AcquitAtBegin'];
              i <= tpl['AcquitAtEnd'];
              i += 86400
            ) {
              data = res.find(
                (e) =>
                  dateFormater(e.AcquitAt, false) === dateFormater(i, false)
              )
              this.isShowNotificationDialogData.dataOption.rows.push({
                采集时间: dateFormater(i, false),
                油烟折算浓度: data ? data.CEmissions : 0,
                颗粒物折算浓度: data ? data.CGranule : 0,
                非甲烷总烃折算浓度: data ? data.CHydrocarbon : 0
              })
            }
            this.isShowNotificationDialog = true
            this.isShowNotificationDialogData.tableData = list
            if (res.length) {
              this.isShowNotificationDialogData.markLine.data.push(
                {
                  lineStyle: {
                    color: '#00A1FF'
                  },
                  yAxis: res[0].LocaleEmissionsSill || 0
                },
                {
                  lineStyle: {
                    color: '#F5B049'
                  },
                  yAxis: res[0].LocaleGranuleSill || 0
                },
                {
                  lineStyle: {
                    color: '#009999'
                  },
                  yAxis: res[0].LocaleHydrocarbonSill || 0
                }
              )
            }
          })
        })
        .catch(() => {
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-exclamation-triangle {
  color: #ebb460;
}

.fa-bell {
  color: #f56c6c;
}

.fa-info-circle {
  color: #a0cfff;
}

// 查看图片
.preview_image_box {
  position: relative;
  // z-index: 1;
  .el-button {
    position: relative;
    // z-index: 2;
  }

  .el-image {
    position: absolute;
    // z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .el-image__inner {
    opacity: 0;
  }
}
</style>
